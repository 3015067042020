@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color-200: #5fbc3f;
  --primary-color-300: #4c9c31;
  --tertiary-color-200: #ffa538;
  --tertiary-color-300: #fc9c27;
  --grayscale-color-300: #b4b3b3;
  --grayscale-color-400: #717171;
  --light-color: #ffffff;

  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
